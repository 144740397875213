<template>
    <div>
        La foto {{ $route.params.id }}
    </div>
</template>

<script>
export default {
    name: "Fotografia"
}
</script>