<template>
    <div class="fotografias">
        <h1>
            Fotografias
        </h1>
        <router-link :to="{ name: 'Fotografias', params: {id:item} }" 
            v-for="(item, index) of fotos" :key="index">
            <button>{{item}}</button>
        </router-link>
        <Fotografia/>

        
        <button @click="anterior()">Anterior</button>
        <button @click="home()">Home</button>
        <button @click="siguiente()">Siguiente</button>
    </div>
</template>

<script>
import Fotografia from '@/components/Fotografia.vue'

export default {
  name: 'Fotografias',
  components: {
    Fotografia
  },
  data(){
      return {
          fotos: [1,2,3]
      }
  },
  methods:{
      home(){
          this.$router.push('/')
      },
      anterior(){
          this.$router.go(-1)
      },
      siguiente(){
          this.$router.go(+1)
      },
  }
}
</script>